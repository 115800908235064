















import Vue from "vue";

export default Vue.extend({
	methods: {
		goToLink() {
			window.location.href = "https://bit.ly/3a22CYm";
		},
	},
	mounted() {
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
});
